<template>
  <div class="loginView">
    <div class="login-form mt-1">
      <div class="section">
        <img
          src="img/logo.png"
          alt="icon"
          class="form-image"
          style="margin-top: 50px"
        />
      </div>
      <div class="section mt-1">
        <h1>Log In</h1>
        <h4>Registration available at https://ocean</h4>
      </div>
      <div class="section mt-1 mb-5">
        <form action="app-pages.html">
          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="email"
                class="form-control"
                id="email1"
                placeholder="Email address"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="password"
                class="form-control"
                id="password1"
                placeholder="Password"
                autocomplete="off"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-links mt-2">
            <div>
              <a href="page-register.html">Register Now</a>
            </div>
            <div>
              <a href="page-forgot-password.html" class="text-muted"
                >Forgot Password?</a
              >
            </div>
          </div>

          <!--Settings-->

          <div class="form-button-group">
            <button
              type="button"
              class="btn btn-primary btn-block btn-lg btn-ocean-action"
              @click="login"
            >
              Log in
            </button>
          </div>
        </form>
      </div>

      <div class="section mt-1">
        <h1>Disclaimer</h1>
        <h4>
          This is NOT a navigation app and is for informational purposes only
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    login() {
      // Implement your login logic here
      // For now, let's assume a successful login
      this.$router.push("/main/map");
    },
  },
};
</script>

<style scoped>
html {
  font-size: 20px !important;
}
.form-control {
  font-size: 16px;
}
.btn {
  border: 1px solid rgb(214, 214, 214);
}
.btn-primary {
}
</style>
