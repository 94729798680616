import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// Create the Vue app
const app = createApp(App);

// Use Vue plugins and mount the app
app.use(store).use(router).mount("#app");
