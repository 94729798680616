// geolocation/index.js
import { ref } from "vue";
import store from "@/store";

const watchId = ref(null);

export function useGeolocation() {
  const startTracking = () => {
    console.log("Start TRacking :");
    try {
      const options = {
        maximumAge: 0,
        enableHighAccuracy: true,
        timeout: 10000,
      };

      watchId.value = navigator.geolocation.watchPosition(
        position => {
          console.log("New position :", position);
          store.commit("setLocationFound", true);
          store.commit("updateLocation", {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            speed: position.coords.speed || 0,
            heading: position.coords.heading || 0,
          });
        },
        error => {
          if (error.code === error.PERMISSION_DENIED) {
            store.commit("setGeolocationError", "Location permission denied");
          }
        },
        options
      );
    } catch (error) {
      console.error("Geolocation tracking failed:", error);
    }
  };

  const stopTracking = () => {
    if (watchId.value !== null) {
      navigator.geolocation.clearWatch(watchId.value);
      store.commit("setWatchId", null);
    }
  };

  return { startTracking, stopTracking };
}
